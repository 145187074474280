














































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Backbone from '../core/Backbone';
import Address, { ECustomerType } from '../models/Address';
import zipcodes from '@/Misc/zipcodes';
import LabelValue from '@/dialogs/LabelValue.vue';
import { UploadFileTypes } from '../core/RA_Other';
import axios, { Canceler } from 'axios';
import _ from 'lodash';
import DTCompany from '@/models/DTCompany';
import { Prop } from 'vue-property-decorator';

@Component({
	components: {
		LabelValue: LabelValue
	}
})
export default class EditCustomerDialog extends Vue {
	data(): any {
		return {
			ECustomerType,
			valid: false,
			isCompany: true,
			visible: false,
			loading: false,
			addressTypes: [
				{ text: 'Erhvervskunde', value: ECustomerType.Company },
				{ text: 'Privatkunde', value: ECustomerType.Private }
			],
			gmap: {
				center: {
					lat: 55.6606437,
					lng: 12.1088032
				},
				zoom: 13
			},
			gpsSet: <'unset' | boolean>'unset',
			requiredField: [(v: any) => !!v || 'Dette felt skal udfyldes!'],
			zip: [
				(v: any) => !!v || 'Dette felt skal udfyldes!',
				(v: string) => (v && v.length == 4 && /^\d+$/.test(v) ? true : 'Postnummeret skal være på fire decimaler')
			],
			nameRequired: [
				() => {
					if (this.$data.isCompany == true && (this.address!.CompanyName == undefined || this.address!.CompanyName.length < 1)) {
						return 'Firmanavn skal udfyldes!';
					} else if (this.$data.isCompany == false && (this.address!.FirstName == undefined || this.address!.FirstName.length < 1)) {
						return 'Navn skal udfyldes!';
					}

					return true;
				}
			],
			fileValidation: [
				(v: any) => {
					if (v == undefined) {
						return true;
					}
					if (v.size > 2500000) {
						return 'Filen er for stor';
					}

					return true;
				}
			],
			city: '',
			uploadFileData: undefined,
			Backbone: Backbone
		};
	}

	showNotValid = false;

	chosenAdress: any = {};
	addressesLoading: boolean = false;
	addressSuggestions: any = [];
	cancelToken: Canceler | null = null;

	private address: Address | null = null;
	private originalAddress: Address | null = null;

	@Prop({ default: null })
	company!: DTCompany | null;

	resultHandler: ((address: Address | undefined) => void) | undefined = undefined;

	queueSearch(event: KeyboardEvent) {
		switch (event.key) {
			case 'Down':
			case 'ArrowDown':
			case 'Up':
			case 'ArrowUp':
			case 'Left':
			case 'ArrowLeft':
			case 'Right':
			case 'ArrowRight':
			case 'Enter':
				break;
			default: {
				const searchString = (event.currentTarget! as HTMLInputElement).value;
				if (searchString.length >= 1) {
					this.dawaSearch(searchString);
				}
				return;
			}
		}
		return;
	}

	setAddress(item: any) {
		if (item.data) {
			// this is a result of lookup chosen
			this.$set(this.address!, 'ZipCode', item.data.postnr);
			this.$set(this.address!, 'City', item.data.postnrnavn);
			this.$set(this.address!.Location, 'Lat', item.data.y);
			this.$set(this.address!.Location, 'Lng', item.data.x);
			this.$data.city = item.data.postnrnavn;
			const lastIndex = this.chosenAdress.forslagstekst.lastIndexOf(',');
			this.chosenAdress.forslagstekst =
				this.chosenAdress.forslagstekst.substr(0, lastIndex) +
				this.chosenAdress.forslagstekst.substr(this.chosenAdress.forslagstekst.length);
			this.$set(this.address!, 'Address', this.chosenAdress.forslagstekst);

			this.$data.gmap.center = {
				lat: this.address!.Location.Lat,
				lng: this.address!.Location.Lng
			};
			this.$data.gpsSet = true;
		} else {
			// this must be astring
			this.$set(this.address!, 'Address', item);
		}
	}

	dawaSearch(searchString: string) {
		if (this.cancelToken) {
			this.cancelToken();
		}

		axios({
			method: 'get',
			url: 'https://api.dataforsyningen.dk/autocomplete?startfra=adgangsadresse&type=adresse&struktur=mini&fuzzy=&q=' + searchString,
			cancelToken: new axios.CancelToken((c) => {
				this.cancelToken = c;
			}),
			responseType: 'json'
		})
			.then((result: any) => {
				this.addressSuggestions = result.data;
			})
			.finally(() => {
				this.addressesLoading = false;
			});
	}

	public SetCustomer(address: Address, resultHandler: ((c: Address | undefined) => void) | undefined) {
		this.resultHandler = resultHandler;
		this.address = _.cloneDeep<Address>(address);
		this.originalAddress = address;
		this.$data.gmap.center.lat = address.Location.Lat;
		this.$data.gmap.center.lng = address.Location.Lng;
		this.$data.gpsSet = address.Id != '' ? true : 'unset';
		this.$data.isCompany = address.CustomerType == ECustomerType.Company;
		this.$data.visible = true;
		this.$data.loading = false;
		this.$data.city = this.address.City;
		this.$data.uploadFileData = undefined;
		this.chosenAdress = undefined;

		if (address && address.Address) {
			this.chosenAdress = {};
			this.chosenAdress.forslagstekst = address.Address;
			this.addressSuggestions[0] = this.chosenAdress;
		}
	}

	MapClickedHandler(e: any) {
		this.address!.Location.Lat = e.latLng.lat();
		this.address!.Location.Lng = e.latLng.lng();
		this.$data.gpsSet = true;
	}

	async LookUpGps() {
		this.$data.loading = true;
		this.address!.City = this.$data.city;
		let r = await Backbone.RemoteActions.Other.LookUpGps(this.address!.FullAddressOneLine);

		if (r.status < 300 && r.data.Results.length > 0) {
			Object.assign(this.address!.Location, r.data.Results[0].Geometry.Location);
			let l = r.data.Results[0].Geometry.Location;

			this.$data.gmap.center = { lat: l.Lat, lng: l.Lng };
			this.$data.gpsSet = true;
			Backbone.UI.PushAlert('Position indsat efter adresse. Husk at tjekke at positionen er korrekt!', 'success', true);
		}

		this.$data.loading = false;
	}

	async OkBtnClicked() {
		this.$data.valid = (this.$refs.form as Vue & { validate: () => boolean }).validate() && this.$data.gpsSet;

		if (this.$data.gpsSet != true) {
			this.$data.gpsSet = false;
		}
		if (!this.$data.valid) {
			this.showNotValid = true;
			return;
		}

		this.$data.loading = true;

		if (this.originalAddress!.ServiceAgreementFileKey != '') {
			if (this.address!.ServiceAgreementFileKey == '') {
				Backbone.UI.Progress.Show('Sletter gammel serviceaftale... ');
				let delAgr = await Backbone.RemoteActions.Other.DeleteFile(this.originalAddress!.ServiceAgreementFileKey);
				if (delAgr.status >= 300) {
					console.error(delAgr.statusText);
				}
			}
		}

		if (this.$data.uploadFileData != undefined) {
			Backbone.UI.Progress.Show('Uploader ny serviceaftale...');
			let newAgr = await Backbone.RemoteActions.Other.UploadFile(UploadFileTypes.ServiceFile, this.$data.uploadFileData);
			if (newAgr.status < 300) {
				this.address!.ServiceAgreementFileKey = newAgr.data.DownloadKey;
				this.address!.ServiceAgreementFileName = newAgr.data.Name;
			} else {
				this.$data.loading = false;
				Backbone.UI.PushApiErrorAlert('Fejl ved upload af serviceaftale!', newAgr, 'error', true);
				Backbone.UI.Progress.Hide();
				return;
			}
		}

		this.address!.City = this.$data.city;
		Backbone.UI.Progress.Show('Gemmer data...');
		let response = await Backbone.RemoteActions.Address.CreateOrPut(this.address!);

		if (!this.originalAddress?.Id) {
			if (this.address?.CompanyId) {
				response = await Backbone.RemoteActions.Address.AddCompanyToAddress(this.address?.CompanyId, response.data.Id);
			}
		} else {
			if (this.originalAddress?.CompanyId && this.address?.CompanyId != this.originalAddress?.CompanyId) {
				response = await Backbone.RemoteActions.Address.RemoveCompanyToAddress(this.originalAddress!.CompanyId!, this.address!.Id);
			}

			if (this.address?.CompanyId) {
				response = await Backbone.RemoteActions.Address.AddCompanyToAddress(this.address!.CompanyId!, this.address!.Id);
			}
		}

		if (response.status < 300) {
			await Backbone.RemotePoller.PollNow(true);
			this.$data.loading = false;

			if (this.resultHandler != undefined) {
				this.resultHandler!(response.data);
			}
			this.$data.visible = false;
			Backbone.UI.Progress.Hide();
			Backbone.UI.PushAlert('Ændringer til kunden er gemt', 'success', true);

			return;
		}

		Backbone.UI.Progress.Hide();

		this.$data.loading = false;
		Backbone.UI.PushApiErrorAlert('Kunne ikke gemme kunden!', response, 'error', true);
	}

	private async DeleteAddress() {
		let result = await Backbone.UI.OpenConfirmDialog(
			'Slet kunde. Er du sikker?',
			'Du er ved at slette kunden "' + this.address!.DisplayName + '".\nHandlingen kan ikke fortrydes.<br />Er du sikker?',
			true,
			{ color: 'error' }
		);

		if (result != true) {
			return;
		}

		this.$data.loading = true;

		let response = await Backbone.RemoteActions.Address.Delete(this.address!.Id);

		await Backbone.RemotePoller.PollNow(true);

		if (response.status < 300) {
			this.$data.visible = false;
			Backbone.UI.PushAlert('Adressen er slettet!', 'success', true);
			if (this.resultHandler != undefined) {
				this.resultHandler(undefined);
			}
		}

		this.$data.loading = false;
	}

	CheckZip() {
		if (this.$data.city == undefined || this.$data.city.length < 1) {
			let city: any = (<any>zipcodes)[this.address!.ZipCode!];
			if (city != undefined) {
				this.$data.city = city;
			}
		}
	}

	async DeleteAgreement() {
		this.address!.ServiceAgreementFileKey = '';
		this.address!.ServiceAgreementFileName = '';
	}

	private DownloadAgreement(address: Address) {
		window.open(Backbone.RemoteActions.GetFilePath(address.ServiceAgreementFileKey));
	}
}
