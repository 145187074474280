
















import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';

@Component({})
export default class Alerts extends Vue {
	data(): any {
		return {
			alerts: []
		};
	}

	public Push(text: string, type: 'success' | 'info' | 'warning' | 'error', dismissible: boolean) {
		let a = {
			text,
			type,
			dismissible,
			visible: true,
			tempId: Math.floor(Math.random() * 99999)
		};
		this.$data.alerts.push(a);
		setTimeout(() => {
			this.remove(a.tempId);
		}, 10000);
	}

	private remove(id: number) {
		this.$data.alerts = this.$data.alerts.filter((x: any) => x.tempId !== id);
	}

	public PushApiError(text: string, error: any, type: 'success' | 'info' | 'warning' | 'error', dismissible: boolean) {
		let a = {
			text,
			type,
			dismissible,
			visible: true,
			tempId: Math.floor(Math.random() * 99999)
		};
		if (error) {
			if (error.data && error.data.Message) {
				a.text += '\nKode: ' + error.data.Message;
			} else if (error.statusText) {
				a.text += '\nKode: ' + error.statusText;
			}
		}
		this.$data.alerts.push(a);
		setTimeout(() => {
			this.remove(a.tempId);
		}, 5000);
	}

	created() {
		Backbone.UI.SetAlerts(this);
	}
}
