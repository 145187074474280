




























import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';

const props = Vue.extend({
	props: {
		label: String,
		value: Object as () => moment.Moment,
		format: {
			type: String,
			default: 'LL'
		},
		noValue: {
			type: String,
			default: 'Ikke angivet'
		},
		disabled: Boolean,
		css: String,
		prependIcon: {
			type: String,
			default: ''
		},
		prependInnerIcon: {
			type: String,
			default: ''
		},
		rules: {
			type: Array,
			default: (): any[] => []
		}
	}
});

@Component({
	watch: {
		value: {
			handler() {
				let a: any = this; //HACK this.RefreshData() does not transpile
				a.UpdateDisplayDate();
			}
		}
	}
})
export default class DateField extends props {
	data(): any {
		return {
			visible: false,
			displayDate: '',
			pickerDate: ''
		};
	}

	created() {
		this.UpdateDisplayDate();
	}

	UpdateDisplayDate() {
		if (this.$props.value) {
			this.$data.pickerDate = this.$props.value.toISOString().substr(0, 10);
			this.$data.displayDate = this.$props.value.format(this.$props.format);
		} else {
			this.$data.displayDate = this.$props.noValue;
			this.$data.pickerDate = moment().toISOString().substr(0, 10);
		}
	}

	UpdateValue() {
		let da = this.$data.pickerDate.split('-');
		let d = moment();
		if (this.$props.value) {
			d = moment(this.$props.value);
		}
		d.year(da[0])
			.month(da[1] - 1)
			.date(da[2]);
		this.$emit('input', d);
		this.$emit('change', d);
	}
}
