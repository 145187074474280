































































































/* eslint-disable prettier/prettier */

import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';
import Organisation from '../models/Organisation';
import LabelValue from '@/dialogs/LabelValue.vue';
import _ from 'lodash';
import { UploadFileTypes } from '@/core/RA_Other';
import TypeScriptUtils from '../Misc/TypeScriptUtils';

@Component({
	filters: {},
	components: {
		LabelValue
	},
	watch: {}
})
export default class OrgSettings extends Vue {
	private Backbone = Backbone;
	private formDisabled: boolean = true;
	private orgData: Organisation = new Organisation();
	private rules = {
		required: (v: any) => !!v || 'Dette felt skal udfyldes!',
		fileValidation: (v: any) => {
			if (v == undefined) {
				return true;
			}
			if (v.size > 4000000) {
				return 'Filen er for stor';
			}

			return true;
		},
		emailRule: (v: any) => {
			return /^$|.+@.+\..+/.test(v) ? true : 'E-mailadressen er ikke formateret korrekt!';
		}
	};
	private valid = false;
	private uploadLogo: any = null;

	mounted() {
		this.stallMounted();
	}

	async stallMounted() {
		await TypeScriptUtils.WaitUntil(()=>Backbone.State.Organisation.Id );
		this.Reset();
	}

	Reset() {
		this.orgData = _.cloneDeep<Organisation>(Backbone.State.Organisation);
		this.formDisabled = true;
	}

	async SaveData() {
		if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) {
			return;
		}

		if (this.uploadLogo) {
			Backbone.UI.Progress.Show('Uploader logo...');

			let uploadResponse = await Backbone.RemoteActions.Other.UploadFile(UploadFileTypes.CompanyLogo, this.uploadLogo);

			if (uploadResponse.status >= 300) {
				Backbone.UI.PushApiErrorAlert('Fejl ved kommunikation med server', uploadResponse, 'error', true);
				Backbone.UI.Progress.Hide();
				return;
			}

			if (Backbone.State.Organisation.Logo) {
				Backbone.UI.Progress.Show('Sletter eksisterende logo...');
				await Backbone.RemoteActions.Other.DeleteFile(Backbone.State.Organisation.Logo);
			}

			this.orgData.Logo = uploadResponse.data.DownloadKey;
		} else if (!this.orgData.Logo && Backbone.State.Organisation.Logo) {
			if (Backbone.State.Organisation.Logo) {
				Backbone.UI.Progress.Show('Sletter eksisterende logo...');
				await Backbone.RemoteActions.Other.DeleteFile(Backbone.State.Organisation.Logo);
			}
		}

		Backbone.UI.Progress.Show('Gemmer organisationsdata...');
		let orgSaveResponse = await Backbone.RemoteActions.Other.PutOrganisation(this.orgData);
		if (orgSaveResponse.status >= 300) {
			Backbone.UI.PushApiErrorAlert('Fejl ved kommunikation med server', orgSaveResponse, 'error', true);
			return;
		} else {
			Backbone.State.Organisation = orgSaveResponse.data;
			this.orgData = _.cloneDeep<Organisation>(Backbone.State.Organisation);
		}
		Backbone.UI.Progress.Hide();
		this.formDisabled = true;
	}

	async DownloadWellData() {
		Backbone.UI.Progress.Show('Beder serveren om at generere en downloadfil...');
		let getKeyResponse = await Backbone.RemoteActions.Other.GenerateWellsArchive();
		if (getKeyResponse.status >= 300) {
			Backbone.UI.Progress.Hide();
			Backbone.UI.PushApiErrorAlert('Fejl ved kommunikation med serveren.', getKeyResponse, 'error', true);
			return;
		}

		Backbone.UI.Progress.Hide();

		const result = await Backbone.UI.OpenConfirmDialog('Download fil', 'Filen er klar til download.', true, { primaryBtnText: 'Download' });
		if (result) {
			window.open(Backbone.RemoteActions.Other.GetWellsDownloadLink(getKeyResponse.data));
		}
	}

	async DownloadOrgData() {
		Backbone.UI.Progress.Show('Beder serveren om at generere en downloadfil...');
		let getKeyResponse = await Backbone.RemoteActions.Other.GenerateOrgArchive();

		if (getKeyResponse.status >= 300) {
			Backbone.UI.Progress.Hide();
			Backbone.UI.PushApiErrorAlert('Fejl ved kommunikation med serevren.', getKeyResponse, 'error', true);
			return;
		}

		let dots = '...';
		Backbone.UI.Progress.Show('Afventer serveren for færdiggørelse af downloadfil...<br /><br />Dette kan tage lang tid!');
		let response = await Backbone.RemoteActions.Other.PollOrgArchive(getKeyResponse.data);
		while (response.status < 300 && response.data != true) {
			await TypeScriptUtils.WaitForSeconds(1);
			dots += '.';
			if (dots.length > 3) {
				dots = '';
			}

			Backbone.UI.Progress.Show('Afventer serveren for færdiggørelse af downloadfil' + dots + '<br /><br />Dette kan tage lang tid!');
			response = await Backbone.RemoteActions.Other.PollOrgArchive(getKeyResponse.data);
		}

		Backbone.UI.Progress.Hide();

		if (response.status < 300) {
			const result = await Backbone.UI.OpenConfirmDialog('Download fil', 'Filen er klar til download.', true, {
				primaryBtnText: 'Download'
			});
			if (result) {
				window.open(Backbone.RemoteActions.Other.GetOrgArchiveDownloadLink(getKeyResponse.data));
			}

			return;
		}
		Backbone.UI.PushApiErrorAlert('Fejl ved kommunikation med serevren', response, 'error', true);
	}

	async MarkOrgForDeleteion() {
		let result = await Backbone.UI.OpenConfirmDialog(
			'Slet din organisation!',
			'Du er ved at slette hele din organisation!<br />Det vil herefter ikke være muligt at tilgå organisationens data.' +
				'<br /><br /><span style=\'text-color: red\'>DENNE HANDLING KAN IKKE FORTRYDES!</span>' +
				'<br /><br />Er du helt sikker?',
			true,
			{ color: 'error', width: 400, primaryBtnText: 'Slet organisationen!' }
		);

		if (result != true) {
			return;
		}

		Backbone.UI.Progress.Show('Sletter...');

		var dresult = await Backbone.RemoteActions.Other.MarkOrgForDeletion(Backbone.State.Organisation.Id!);

		if (dresult.status < 300) {
			Backbone.State.Clear();
			this.$router.push('/');
			return;
		}

		Backbone.UI.PushApiErrorAlert('Fejl ved sletning af organisation.', dresult, 'error', true);
	}
}
