






















































import Vue from 'vue';
import Backbone from '@/core/Backbone';
import Component from 'vue-class-component';
import moment from 'moment';
import { EAddressState } from '@/models/Address';
import DTCompany from '@/models/DTCompany';
import CompanyDetails from '@/views/CompanyDetails.vue';
import EditCompanyDialog from '@/dialogs/EditCompanyDialog.vue';

@Component({
	filters: {
		moment: function (date: moment.Moment) {
			if (date == undefined) {
				return '-';
			}
			return date.format('L');
		}
	},
	components: {
		CompanyDetails,
		EditCompanyDialog
	},
	watch: {}
})
export default class AddressList extends Vue {
	private headers = [
		{
			text: 'Kundegruppe',
			align: 'left',
			sortable: true,
			value: 'Name'
		},
		{
			text: 'Antal kunder',
			sortable: true,
			value: 'AddressCount',
			width: 90,
			align: 'right'
		}
	];

	private companies: DTCompany[] = [];
	private companySearchString = '';
	private selectedCompany: DTCompany | null = null;
	private loading = true;

	async mounted() {
		this.RefreshData();
		Backbone.State.OnChanged.subscribe(() => this.RefreshData());
		const id = this.$route.query['id'];
		if (id) {
			this.selectedCompany = Backbone.State.Companies.find((c) => c.Id == id)!;
			(<any>this.$refs.CompanyDetails).SetCompany(this.selectedCompany);
		}
	}

	private RefreshData() {
		this.loading = true;
		let comp: DTCompany[] = [];

		Backbone.State.Companies.forEach((c) => {
			if (c.State != EAddressState.Active) {
				return;
			}

			if (this.companySearchString) {
				let str = this.companySearchString.toLocaleUpperCase();
				if (c.Name.toLocaleUpperCase().includes(str)) {
					comp.push(c);
				}
			} else {
				comp.push(c);
			}
		});

		this.companies = comp;
		this.loading = false;
	}

	CreateCompanyBtnClicked() {
		(<any>this.$refs.editCompanyDialog).SetCompany(new DTCompany(), this.handleNewCompanyClosed);
	}

	handleNewCompanyClosed(company: DTCompany | undefined) {
		this.SelectCompany(company!);
	}

	async SelectCompany(company: DTCompany | null) {
		this.selectedCompany = company;
		if (!company) {
			this.$router.push('');

			return;
		}
		this.$router.push('?id=' + company?.Id);

		(<any>this.$refs.CompanyDetails).SetCompany(company);
	}
}
