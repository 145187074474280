































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import Backbone from '../core/Backbone';
import moment from 'moment';
import Well, { DTBarrier } from '../models/Well';
import BarrierType from '../models/BarrierType';
import DateField from '@/dialogs/DateField.vue';
import Address from '../models/Address';
import _ from 'lodash';
import { UploadFileTypes } from '../core/RA_Other';
import FileInfo from '../models/FileInfo';
import UITools from '@/Misc/UITools';
import ThumbImage from '@/views/ThumbImage.vue';

@Component({
	components: {
		DateField: DateField,
		ThumbImage
	}
})
export default class EditWellDialog extends Vue {
	data(): any {
		return {
			valid: false,
			formDisabled: true,
			rules: {
				required: [(v: any) => !!v || 'Dette felt skal udfyldes!'],
				latestService: [
					() => {
						if (!this.latestServiceVisit) {
							return true;
						}
						if (this.latestServiceVisit.isAfter(moment(), 'day')) {
							return 'Dato kan ikke være i fremtiden';
						}

						if (this.latestServiceVisit.isBefore(this.well!.InstallationDate, 'day')) {
							return 'Dato kan ikke være før "Dato for montage"';
						}

						return true;
					}
				],
				installedDate: [
					() => {
						if (this.well!.InstallationDate.isAfter(moment(), 'day')) {
							return 'Dato kan ikke være i fremtiden';
						}
						if (this.latestServiceVisit) {
							if (this.latestServiceVisit.isBefore(this.well!.InstallationDate, 'day')) {
								return 'Dato kan ikke være efter "Seneste service dato"';
							}
						}

						return true;
					}
				],
				wellValid: [
					(v: string) => {
						if (!v) {
							return 'Dybden skal angives';
						}

						if (!/^\+?(0|[1-9]\d*)$/.test(v)) {
							return 'Værdien skal være større en nul';
						}

						return true;
					}
				]
			},
			loading: false,
			originalWell: <Well | undefined>undefined,
			wellMaterials: ['Plast', 'Beton', 'Andet'],
			installationBrands: ['Nordisk Innovation', 'Andet'],
			installationTypes: <BarrierType[]>[],
			serviceIntervals: [
				{ value: 1, text: '1 måned' },
				{ value: 2, text: '2 måneder' },
				{ value: 3, text: '3 måneder' },
				{ value: 4, text: '4 måneder' },
				{ value: 5, text: '5 måneder' },
				{ value: 6, text: '6 måneder' },
				{ value: 7, text: '7 måneder' },
				{ value: 8, text: '8 måneder' },
				{ value: 9, text: '9 måneder' },
				{ value: 10, text: '10 måneder' },
				{ value: 11, text: '11 måneder' },
				{ value: 12, text: '1 år' },
				{ value: 13, text: '1 år og 1 måned' },
				{ value: 14, text: '1 år og 2 måneder' },
				{ value: 15, text: '1 år og 3 måneder' },
				{ value: 16, text: '1 år og 4 måneder' },
				{ value: 17, text: '1 år og 5 måneder' },
				{ value: 18, text: '1 år og 6 måneder' },
				{ value: 19, text: '1 år og 7 måneder' },
				{ value: 20, text: '1 år og 8 måneder' },
				{ value: 21, text: '1 år og 9 måneder' },
				{ value: 22, text: '1 år og 10 måneder' },
				{ value: 23, text: '1 år og 11 måneder' },
				{ value: 24, text: '2 år' },
				{ value: 25, text: '2 år og 1 måned' },
				{ value: 26, text: '2 år og 2 måneder' },
				{ value: 27, text: '2 år og 3 måneder' },
				{ value: 28, text: '2 år og 4 måneder' },
				{ value: 29, text: '2 år og 5 måneder' },
				{ value: 30, text: '2 år og 6 måneder' },
				{ value: 31, text: '2 år og 7 måneder' },
				{ value: 32, text: '2 år og 8 måneder' },
				{ value: 33, text: '2 år og 9 måneder' },
				{ value: 34, text: '2 år og 10 måneder' },
				{ value: 35, text: '2 år og 11 måneder' },
				{ value: 36, text: '3 år' }
			],
			Backbone: Backbone,
			gmap: {
				center: {
					lat: 55.6606437,
					lng: 12.1088032
				},
				zoom: 19
			},
			UITools
		};
	}

	showNotValid = false;

	private fileValidation: any[] = [
		(v: any) => {
			if (v == undefined) {
				return true;
			}
			if (v.size > 4000000) {
				return 'Filen er for stor';
			}

			return true;
		}
	];

	private dialogOpen: boolean = false;
	private well: Well | null = null;
	resultHandler: ((well: Well | undefined) => void) | undefined = undefined;
	private latestServiceVisit: moment.Moment | null = null;
	private address: Address | null = null;

	private photosInWell: FileInfo[] = [];
	private photosOther: FileInfo[] = [];
	private newImagesInWell: any[] = [];
	private newImages: any[] = [];

	async mounted() {
		this.$data.installationTypes = BarrierType.Types;
	}

	public Open(well: Well, address: Address, resultHandler: ((inst: Well | undefined) => void) | undefined) {
		this.showNotValid = false;
		if (!address) {
			address = Backbone.State.Addresses.find((a: Address) => a.Id == this.well!.CustomerId)!;
		}

		this.resultHandler = resultHandler;
		this.$data.originalWell = well;
		this.address = address;

		this.Reset();

		this.$data.formDisabled = well.Id != '';
		this.dialogOpen = true;
	}

	Reset() {
		this.newImages = [];

		this.$data.formDisabled = true;
		this.newImages = [];
		this.well = _.cloneDeep<Well>(this.$data.originalWell);
		if (!this.well.Barriers || this.well.Barriers.length == 0) {
			this.well.Barriers = [new DTBarrier()];
		}
		if (!this.well.Id) {
			const address = Backbone.State.Addresses.find((a: Address) => a.Id == this.well!.CustomerId);
			this.well.Location = _.cloneDeep(address!.Location);
			this.latestServiceVisit = null;
			this.well.InstallationDate = moment();
		} else {
			this.latestServiceVisit = this.well.LatestServiceVisit;
		}

		this.$data.gmap.center.lat = this.address!.Location.Lat;
		this.$data.gmap.center.lng = this.address!.Location.Lng;

		this.photosInWell = [];
		this.photosOther = [];
		this.well.Images.forEach((photo) => {
			if (photo.Section == 'InWell') {
				this.photosInWell.push(photo);
			} else {
				this.photosOther.push(photo);
			}
		});
	}

	Close() {
		this.$data.dialogOpen = false;
		this.$data.well = null;
		this.$data.formDisabled = true;
		this.newImages = [];
		this.newImagesInWell = [];
		this.photosInWell = [];
		this.photosOther = [];
	}

	validate() {
		if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) {
			this.showNotValid = true;
			return false;
		}

		this.showNotValid = false;
		return true;
	}

	async OkBtnClicked() {
		if (!this.validate()) {
			return;
		}

		this.showNotValid = false;

		this.$data.loading = true;

		this.well!.Images = this.photosInWell.concat(this.photosOther);

		let uploadCounter = 0;
		for (let sectionId = 0; sectionId < 2; sectionId++) {
			let images = this.newImagesInWell;
			let sectionTag = 'InWell';

			if (sectionId == 1) {
				sectionTag = 'Other';
				images = this.newImages;
			}

			for (let i = 0; i < images.length; i++) {
				const imageData = images[i];
				if (imageData == undefined) {
					continue;
				}

				uploadCounter++;
				Backbone.UI.Progress.Show('Uploader billede ' + uploadCounter);
				let uploadResponse = await Backbone.RemoteActions.Other.UploadFile(UploadFileTypes.Installation, imageData, sectionTag);

				if (uploadResponse.status < 300) {
					this.well!.Images.push(uploadResponse.data);
				} else {
					this.$data.loading = false;
					Backbone.UI.PushApiErrorAlert('Fejl ved kommunikation med server', uploadResponse, 'error', true);
					Backbone.UI.Progress.Hide();
					return;
				}
			}
		}

		Backbone.UI.Progress.Show('Gemmer brønddata...');
		if (this.latestServiceVisit != null) {
			this.well!.LatestServiceVisit = moment(this.latestServiceVisit);
		} else {
			this.well!.LatestServiceVisit = moment(this.well!.InstallationDate);
		}

		let wellResponse = await Backbone.RemoteActions.Address.CreateOrPutInstallation(this.well!);
		if (wellResponse.status >= 300) {
			this.$data.loading = false;
			Backbone.UI.PushApiErrorAlert('Fejl ved kommunikation med server', wellResponse, 'error', true);
			Backbone.UI.Progress.Hide();
			return;
		}

		for (let origI = 0; origI < this.$data.originalWell.Images.length; origI++) {
			let image = this.$data.originalWell.Images[origI] as FileInfo;
			var found = true;
			if (image.Section == 'InWell') {
				found = this.photosInWell.includes(image);
			} else {
				found = this.photosOther.includes(image);
			}

			if (found) {
				Backbone.UI.Progress.Show('Sletter billeder...');
				await Backbone.RemoteActions.Other.DeleteFile(image.DownloadKey);
			}
		}

		this.$data.originalWell = wellResponse.data;
		this.Reset();

		let createInstReport = true;
		if (this.well!.Id != '') {
			createInstReport = await Backbone.UI.OpenConfirmDialog(
				'Opret installationrapport?',
				'Opret ny installationsrapport med de gemte ændringer?',
				true,
				{ primaryBtnText: 'Ja', cancelBtnText: 'Nej' }
			);
		}
		if (createInstReport) {
			Backbone.UI.Progress.Show('Opretter installationsrapport...');
			let instResponse = await Backbone.RemoteActions.Address.CreateInstallationReport(wellResponse.data.Id);
			if (instResponse.status >= 300) {
				this.$data.loading = false;
				Backbone.UI.Progress.Hide();

				Backbone.UI.PushApiErrorAlert('Fejl ved kommunikation med server', instResponse, 'error', true);
				return;
			}
		}
		await Backbone.RemotePoller.PollNow(true);

		Backbone.UI.Progress.Hide();
		this.$data.loading = false;
		this.$data.formDisabled = true;
		this.Close();
		Backbone.UI.PushAlert('Ændringer til brønd er gemt', 'success', true);
	}

	private async DeleteInstallation() {
		let result = await Backbone.UI.OpenConfirmDialog(
			'Slet brønd. Er du sikker?',
			'Du er ved at slette brønden "' + this.well!.Name + '".\nHandlingen kan ikke fortrydes.<br />Er du sikker?',
			true,
			{ color: 'error' }
		);

		if (result != true) {
			return;
		}

		this.$data.loading = true;

		let response = await Backbone.RemoteActions.Address.DeleteInstallation(this.well!.Id);

		await Backbone.RemotePoller.PollNow(true);

		if (response.status < 300) {
			this.dialogOpen = false;
			Backbone.UI.PushAlert('Brønden er slettet!', 'success', true);
			if (this.resultHandler != undefined) {
				this.resultHandler(undefined);
			}
		}

		this.$data.loading = false;
	}

	RemovePhoto(fi: FileInfo) {
		if (fi.Section == 'InWell') {
			const index = this.photosInWell.indexOf(fi);
			this.photosInWell.splice(index, 1);
		} else {
			const index = this.photosOther.indexOf(fi);
			this.photosOther.splice(index, 1);
		}
	}

	MapClickedHandler(e: any) {
		if (this.$data.formDisabled) {
			return;
		}
		this.well!.Location.Lat = e.latLng.lat();
		this.well!.Location.Lng = e.latLng.lng();
	}

	removeBarrier(index: number) {
		if (this.well!.Barriers.length < 2) {
			Backbone.UI.PushAlert('Kan ikke fjerne rottespærren! Der skal mindst være en rottespærre i brønden!', 'warning', true);
			return;
		}

		this.well!.Barriers.splice(index, 1);
	}

	addBarrier() {
		const b = new DTBarrier();
		b.BarrierType = 'TX11';
		b.BarrierBrand = 'Nordisk Innovation';
		b.InstallationDirection = 'NA';
		this.well?.Barriers.push(b);
	}
}
