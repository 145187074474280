<template>
	<v-app id="inspire">
		<v-app-bar app color="indigo" dark class="elevation-1">
			<v-toolbar-title>Nordisk Innovation</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn @click="print()" class="hideIfPrint" color="primary"><v-icon>mdi-printer</v-icon>&nbsp;Udskriv</v-btn>
		</v-app-bar>

		<v-main>
			<v-container fluid>
				<router-view class="print"> </router-view>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
export default {
	props: {
		source: String
	},
	data: () => ({
		drawer: null,
		print() {
			window.print();
		}
	})
};
</script>

<style scoped>
@media screen {
	.print {
		padding: 0 51px;
		max-width: 900px;
		margin: 0 auto;
	}
}

@media print {
	.hideIfPrint {
		visibility: hidden;
	}
}
</style>
