



























































import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';
import User from '../models/User';
import Organisation from '../models/Organisation';
import ConfirmDialog from '../dialogs/ConfirmDialog.vue';

@Component({
	filters: {},
	components: {
		ConfirmDialog: ConfirmDialog
	}
})
export default class ConfirmOrg extends Vue {
	data(): any {
		return {
			loading: true,
			user: <User>{},
			organisation: <Organisation>{},
			token: ''
		};
	}

	async mounted() {
		this.$data.token = this.$route.query.token;
		if (!this.$data.token) {
			this.$router.push('/');
			return;
		}
		let response = await Backbone.RemoteActions.Onboarding.ViewOrganization(this.$data.token);
		this.$data.loading = false;
		if (response.status < 300) {
			this.$data.user = response.data.User;
			this.$data.organisation = response.data.Organization;
			return;
		}
	}

	async ConfirmBtnClicked(approval: boolean) {
		this.$data.loading = true;

		let response = await Backbone.RemoteActions.Onboarding.ConfirmOrganization(this.$data.token, approval);
		this.$data.loading = true;

		if (response.status < 300) {
			await Backbone.UI.OpenConfirmDialog('Anmodning er bekræftet!', 'Anmodning er bekræftet.', false);

			this.$router.push('/');

			return;
		}
	}
}
