
















































































































/* eslint-disable no-console */

import Vue from 'vue';
import Component from 'vue-class-component';
import Backbone from '../core/Backbone';
import ConfirmDialog from '@/dialogs/ConfirmDialog.vue';
import Alerts from '@/dialogs/Alerts.vue';
import Progress from '@/dialogs/Progress.vue';
import BannerCarousel from '@/views/BannerCarousel.vue';
import moment from 'moment';
const VersionInfo = require('@/VersionInfo.js').Version;

@Component({
	components: {
		ConfirmDialog: ConfirmDialog,
		Alerts: Alerts,
		Progress,
		BannerCarousel
	},
	watch: {
		$route: {
			handler: function (to) {
				this.$data.title = to.name;
			},
			deep: true,
			immediate: true
		}
	}
})
export default class baseline extends Vue {
	data(): any {
		return {
			drawer: null,
			alertText: '',
			alert: false,
			title: 'Title',
			Backbone: Backbone,
			VersionInfo: VersionInfo
		};
	}

	async mounted() {
		if (!(await Backbone.State.InitState())) {
			//What in the world is this??!!
			if (document.location.pathname != '/') {
				console.log('Redirecting to login');
				Backbone.RemotePoller.Stop();
				document.location.href = '/';
			}
		}
		if (this.missingOrgFields()) {
			let result = await Backbone.UI.OpenConfirmDialog(
				'Stamoplysninger mangler',
				'Stamoplysningerne på organisationen er ufuldstændige og skal opdateres. Ønsker du at opdatere disse data nu?',
				true,
				{ color: 'warning', width: 500 }
			);
			if (result) {
				this.$router.push('orgsettings');
			}
		}
		this.$data.title = this.$route.name;
	}

	Logout() {
		Backbone.State.Clear();
		this.$router.push('/');
	}

	missingOrgFields() {
		if (
			!Backbone.State.Organisation.Name ||
			!Backbone.State.Organisation.CVRnumber ||
			!Backbone.State.Organisation.Address ||
			!Backbone.State.Organisation.ContactName ||
			!Backbone.State.Organisation.ContactEmail ||
			!Backbone.State.Organisation.Phonenumber
		) {
			return true;
		}
		return false;
	}

	BuildInfo() {
		return 'Build date:' + moment(VersionInfo.buildDate).format('lll');
	}

	gotoHome() {
		this.$router.push({ path: 'addresslist', query: {} });
		this.$router.go(0);
	}
}
