











import Vue from 'vue';
import Component from 'vue-class-component';

// Define the props by using Vue's canonical way.
const props = Vue.extend({
	props: {
		label: String,
		value: String,
		hideIfNoValue: {
			type: Boolean,
			default: true
		}
	}
});

@Component({
	watch: {
		value: {
			handler() {
				let inst: any = this; //HACK this.RefreshData() does not transpile
				if (inst.$props.hideIfNoValue && !this.$props.value) {
					inst.$data.displayStyle = 'display: None';
				} else {
					inst.$data.displayStyle = 'flex-wrap: nowrap;';
				}
			}
		}
	}
})
export default class LabelValue extends props {
	data(): any {
		return {
			displayStyle: ''
		};
	}
}
