






















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	components: {}
})
export default class ThumbImage extends Vue {
	@Prop(String)
	readonly src: string | undefined;

	@Prop({ default: false })
	readonly deletable!: boolean;

	@Prop()
	readonly id: unknown | undefined;

	private srcOrFallback? = '/gfx/dummy_photo_grey.png';
	private fallback?: string = '/gfx/photo_broken.png';

	mounted() {
		this.srcOrFallback = this.src;
	}

	@Watch('src')
	onSrcChanged(val: string | undefined): void {
		this.srcOrFallback = val;
	}

	private handleError() {
		this.srcOrFallback = this.fallback;
	}

	private OpenPhoto() {
		window.open(this.src);
	}
}
