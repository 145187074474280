









































































































/* eslint-disable prettier/prettier */

import Vue from 'vue';
import Component from 'vue-class-component';
import User from '@/models/User';
import Backbone from '../core/Backbone';
import moment from 'moment';
import Address from '../models/Address';
import ScheduledVisit from '../models/ScheduledVisit';
import { AxiosResponse } from 'axios';
import DateField from '@/dialogs/DateField.vue';
import TimeField from '@/dialogs/TimeField.vue';
import LabelValue from '@/dialogs/LabelValue.vue';
import UITools from '@/Misc/UITools';
import Well, { EWellServiceStatus } from '@/models/Well';
import _ from 'lodash';

@Component({
	props: {
		usersData: <any>{}
	},
	watch: {},
	components: {
		DateField,
		TimeField,
		LabelValue
	}
})
export default class EditServiceVisit extends Vue {
	data(): any {
		return {
			valid: false,
			loading: false,
			visible: false,
			rules: {
				ToTimeAfterFromTime: [
					() => {
						this.FixToDate();
						if (this.scheduledVisit!.TaskEndDate.isSameOrAfter(moment(this.scheduledVisit!.TaskStartDate))) {
							return true;
						}
						return 'Skal være før \'fra tid\'!';
					}
				],
				AfterNow: [
					() => {
						if (this.scheduledVisit!.TaskStartDate.isSameOrAfter(moment(), 'day')) {
							return true;
						}
						return 'Du kan ikke vælge en tid før dags dato!';
					}
				],
				Required: [
					(v: any) => {
						return v != undefined && v != '' ? true : 'Feltet ska udfyldes!';
					}
				]
			},
			UITools,
			EWellServiceStatus,
			formDisabled: true,
			Backbone
		};
	}

	private user: User | null = null;
	private address: Address | null = null;
	private includedWells: any = {};
	private scheduledVisit: ScheduledVisit = new ScheduledVisit();
	private showNoWellSelectedWarning = false;
	private lockType: 'Address' | 'User' | 'None' = 'None';

	resultHandler: ((c: ScheduledVisit | undefined) => void) | undefined = undefined;

	async Open(scheduledVisit: ScheduledVisit, resultHandler?: (c: ScheduledVisit | undefined) => void) {
		this.scheduledVisit = _.cloneDeep<ScheduledVisit>(scheduledVisit);

		if (this.scheduledVisit.TaskStartDate.isBefore(moment(), 'days')) {
			this.scheduledVisit.TaskStartDate = moment();
		}

		this.FixToDate();

		this.$data.loading = true;

		this.resultHandler = resultHandler;

		if (scheduledVisit.UserId) {
			let usr = Backbone.State.GetUserById(scheduledVisit.UserId!);
			this.user = usr ? usr : null;
		} else {
			this.user = null;
		}

		let adr = Backbone.State.Addresses.find((v) => v.Id == scheduledVisit.CustomerId);
		this.address = adr ? adr : null;

		this.includedWells = {};
		this.UpdateIncludedWells();

		if (scheduledVisit.CustomerId) {
			this.lockType = 'Address';
		} else if (scheduledVisit.UserId) {
			this.lockType = 'User';
		} else {
			this.lockType = 'None';
		}

		this.$data.formDisabled = scheduledVisit.Id != undefined;
		this.$data.visible = true;

		this.$data.loading = false;
	}

	FixToDate() {
		let e = moment(this.scheduledVisit.TaskEndDate);
		this.scheduledVisit.TaskEndDate = moment(this.scheduledVisit.TaskStartDate).set({ hour: e.get('hour'), minute: e.get('minute') });
	}

	private UpdateIncludedWells() {
		if (!this.address) {
			return;
		}
		this.address!.Wells.forEach((well: Well) => {
			if (!this.scheduledVisit.Id) {
				// new scheduled visit
				this.includedWells[well.Id] = true;
			} else {
				//existing visit
				this.includedWells[well.Id] = this.scheduledVisit.InstallationIds.find((w) => w == well.Id) != undefined;
			}
		});
	}

	async OkBtnClicked() {
		if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) {
			return;
		}

		let includedWells: string[] = [];
		this.address!.Wells.forEach((well: Well) => {
			if (this.includedWells[well.Id] == true) {
				includedWells.push(well.Id);
			}
		});

		this.scheduledVisit.InstallationIds = includedWells;

		// this.showNoWellSelectedWarning = includedWells.length == 0;
		// if (this.showNoWellSelectedWarning)
		// 	return;

		this.$data.loading = true;

		this.scheduledVisit.UserId = this.user!.Id;
		this.scheduledVisit.UserName = this.user!.Name;
		this.scheduledVisit.CustomerId = this.address!.Id;
		(this.scheduledVisit.CustomerName = this.address!.DisplayName),
			(this.scheduledVisit.CustomerAddress = this.address!.FullAddressOneLine),
			(this.scheduledVisit.Location = _.cloneDeep(this.address!.Location));
		this.scheduledVisit.OrganizationId = this.address!.OrganizationId;

		let response: AxiosResponse<ScheduledVisit>;
		if (this.scheduledVisit.Id) {
			response = await Backbone.RemoteActions.ScheduledVisit.UpdateScheduledVisit(this.scheduledVisit);
		} else {
			response = await Backbone.RemoteActions.ScheduledVisit.CreateScheduledVisit(this.scheduledVisit);
		}

		await Backbone.RemotePoller.PollNow(true);

		this.$data.loading = false;

		if (this.resultHandler != undefined) {
			if (response.status < 300) {
				this.resultHandler(response.data);
			} else {
				this.resultHandler(undefined);
			}
		}

		this.$data.visible = false;
	}

	async CancelVisitBtnClicked() {
		let confirm = await Backbone.UI.OpenConfirmDialog(
			'Slet Servicebesøg?',
			'Er du sikker på du vil slette det planlagte servicebesøg?',
			true
		);

		if (!confirm) {
			return;
		}

		this.$data.loading = true;

		let response = await Backbone.RemoteActions.ScheduledVisit.DeleteScheduledVisit(this.scheduledVisit.Id!);

		await Backbone.RemotePoller.PollNow(true);

		this.$data.visible = false;

		if (this.resultHandler != undefined) {
			this.resultHandler(response.data);
		}
	}

	StartDateChanged(v: moment.Moment) {
		this.scheduledVisit.TaskEndDate = moment(v).add(1, 'hour');
	}
}
