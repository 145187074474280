

































import Vue from 'vue';
import Component from 'vue-class-component';
import LabelValue from '@/dialogs/LabelValue.vue';
import DTCompany from '@/models/DTCompany';
import EditCompanyDialog from '@/dialogs/EditCompanyDialog.vue';
import { EAddressState } from '@/models/Address';

@Component({
	components: {
		LabelValue: LabelValue,
		EditCompanyDialog
	}
})
export default class CompanyDetails extends Vue {
	private company: DTCompany | null = null;

	private HandleAddressChanged(e: DTCompany) {
		if (e.Id == this.company!.Id) {
			if (e.State == EAddressState.Active) {
				this.SetCompany(e);
			} else {
				this.SetCompany(null);
			}
		} else {
			e.OnChanged.unsubscribe(this.HandleAddressChanged);
		}
	}

	public async SetCompany(company: DTCompany | null) {
		if (this.company) {
			this.company.OnChanged.unsubscribe(this.HandleAddressChanged);
		}

		this.company = company;
		if (!company) {
			return;
		}

		company?.OnChanged.subscribe(this.HandleAddressChanged);
	}

	EditCompany() {
		(<EditCompanyDialog>this.$refs.editCompanyDialog).SetCompany(this.company!, this.handleEditClosed);
	}

	handleEditClosed(company: DTCompany | undefined) {
		this.SetCompany(company!);
	}

	viewAllAddresses() {
		this.$router.push({ path: 'addresslist', query: { selectedCompanyId: this.company?.Id } });
	}
}
