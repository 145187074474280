























import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';

@Component({})
export default class ConfirmDialog extends Vue {
	data(): any {
		return {
			dialog: false,
			resolve: null,
			reject: null,
			message: null,
			title: null,
			options: {
				color: 'primary',
				width: 290,
				zIndex: 200,
				primaryBtnText: 'Ok',
				cancelBtnText: 'Annuller'
			},
			optionsOriginal: {
				color: 'primary',
				width: 290,
				zIndex: 200,
				primaryBtnText: 'Ok',
				cancelBtnText: 'Annuller'
			},
			cancelVisible: true
		};
	}

	public async open(title: string, message: string, showCancelBtn: boolean, options?: any): Promise<boolean> {
		this.$data.dialog = true;
		this.$data.title = title;
		this.$data.message = message;
		this.$data.cancelVisible = showCancelBtn;
		this.$data.options = Object.assign(this.$data.optionsOriginal, options);
		return new Promise((resolve, reject) => {
			this.$data.resolve = resolve;
			this.$data.reject = reject;
		});
	}

	agree() {
		this.$data.resolve(true);
		this.$data.dialog = false;
	}

	cancel() {
		this.$data.resolve(false);
		this.$data.dialog = false;
	}

	created() {
		Backbone.UI.SetConfirmDialog(this);
	}

	inputChanged(e: any) {
		if (!e) {
			this.cancel();
		}
	}
}
