export default class InstallationType {
	public static readonly Types: InstallationType[] = [
		new InstallationType('TX11 LOCK Ø100/110'),
		new InstallationType('TX11 LOCK Ø100/110 MINI'),
		new InstallationType('TX11 LOCK Ø100/110'),
		new InstallationType('TX11 LOCK Ø105'),
		new InstallationType('TX11 LOCK Ø125'),
		new InstallationType('TX11 LOCK Ø125'),
		new InstallationType('TX11 LOCK Ø125'),
		new InstallationType('TX11 LOCK Ø130'),
		new InstallationType('TX11 LOCK Ø135'),
		new InstallationType('TX11 LOCK Ø150/160'),
		new InstallationType('TX11 LOCK Ø150/160 MINI'),
		new InstallationType('TX11 LOCK Ø150/160'),
		new InstallationType('TX11 LOCK Ø160'),
		new InstallationType('TX11 LOCK Ø160'),
		new InstallationType('TX11 LOCK Ø160'),
		new InstallationType('TX11 Universal Ø100 Bet. - 110 Pvc'),
		new InstallationType('TX11 Universal Ø125'),
		new InstallationType('TX11 Universal Ø150 Bet. - Ø160 Pvc'),
		new InstallationType('TX11 Universal Ø200 Beton'),
		new InstallationType('TX11 Universal Ø200 Pvc'),
		new InstallationType('TX11 Universal Ø200 Ultra'),
		new InstallationType('TX11 Universal Ø250 Beton'),
		new InstallationType('TX11 Universal Ø250 Pvc'),
		new InstallationType('TX11 Universal Ø250 Ultra'),
		new InstallationType('TX11 Universal Ø300 bet. - 315 Pvc'),
		new InstallationType('TX11 Universal Ø315 Ultra'),
		new InstallationType('TX11 Universal Ø350 Bet. - 400 Ultra'),
		new InstallationType('TX11 Universal Ø400 Pvc'),
		new InstallationType('TX11 Universal Ø400 Beton'),
		new InstallationType('Specialmål Ø82 Indv. Rørmål'),
		new InstallationType('Specialmål Ø105 Indv. Rørmål'),
		new InstallationType('Specialmål Ø125 Indv. Rørmål'),
		new InstallationType('Specialmål Ø135 Indv. Rørmål'),
		new InstallationType('Specialmål Ø160 Indv. Rørmål'),
		new InstallationType('Specialmål Ø180 Indv. Rørmål'),
		new InstallationType('Specialmål Ø230 Indv. Rørmål'),
		new InstallationType('Reline Ø100 Bet. - 110 Pvc Reline'),
		new InstallationType('Reline Ø125 Pvc Reline'),
		new InstallationType('Reline Ø150 Bet. -160 Pvc Reline'),
		new InstallationType('Reline Ø200 Bet. Reline'),
		new InstallationType('Reline Ø200 Pvc Reline'),
		new InstallationType('Reline Ø200 Ultra Reline'),
		new InstallationType('Reline Ø250 Bet. Reline'),
		new InstallationType('Reline Ø250 Pvc Reline'),
		new InstallationType('Reline Ø250 Ultra Reline'),
		new InstallationType('Reline Ø300 bet. - 315 Pvc Reline'),
		new InstallationType('TX11 Mini Ø100 Bet. - 110 Pvc MINI'),
		new InstallationType('TX11 Mini Ø150 Bet. - 160 Pvc MINI'),
		new InstallationType('PA6 Ø100 Beton'),
		new InstallationType('PA6 Ø160 Beton'),
		new InstallationType('TX11 Flow Ø100 Bet. - 110 Pvc FLOW'),
		new InstallationType('TX11 Flow Ø150 Bet. - 160 Pvc FLOW'),
		new InstallationType('PipeLife brønd Ø160 Pvc PipeLife'),
		new InstallationType('PipeLife brønd Ø200 Ultra PipeLife'),
		new InstallationType('PipeLife brønd Ø200 Pvc PipeLife'),
		new InstallationType('TX11'),
		new InstallationType('Andet', 'other')
	];

	public Name: string = '';
	private _id?: string;

	public get Id(): string {
		return this._id || this.Name;
	}

	public set Id(value: string) {
		this._id = value;
	}

	constructor(name: string, id?: string) {
		this.Name = name;
		this._id = id;
	}
}
