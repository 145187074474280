


















































































import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';

@Component({
	filters: {}
})
export default class CreateOrg extends Vue {
	data(): any {
		return {
			valid: true,
			orgName: '',
			orgCVR: '',
			orgAddress: '',
			orgPhonenumber: '',
			name: '',
			email: '',
			newsletter: false,
			loading: false,
			alertText: '',
			dialog: false,
			alert: false,
			CVRRules: [(v: any) => !!v || 'CVR skal udfyldes.'],
			addressRules: [(v: any) => !!v || 'Adresse skal udfyldes.'],
			phoneRules: [(v: any) => !!v || 'Telefonnummer skal udfyldes.'],
			orgNameRules: [(v: any) => !!v || 'Organisation navn skal udfyldes.'],
			nameRules: [(v: any) => !!v || 'Navn skal udfyldes.'],
			emailRules: [
				(v: any) => !!v || 'E-mail skal udfyldes.',
				(v: any) => /.+@.+\..+/.test(v) || 'E-mailadressen er ikke formateret korrekt!'
			]
		};
	}

	async CreateOrg() {
		if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) {
			return;
		}
		this.$data.loading = true;
		let result = await Backbone.RemoteActions.Onboarding.CreateOrganisation(
			this.$data.orgName,
			this.$data.email,
			this.$data.name,
			this.$data.orgPhonenumber,
			this.$data.orgCVR,
			this.$data.orgAddress,
			this.$data.newsletter
		);

		this.$data.loading = false;
		if (result instanceof Error) {
			this.SetAlert('Fejl ved oprettelse af organisation!\n' + result);
			return;
		}

		if (result.status < 300) {
			this.$data.dialog = true;
			// Backbone.RemoteActions.authToken = result.data.Token;
			return;
		}
		this.SetAlert('Fejl ved oprettelse af organisation!\nKode: ' + result.statusText);
	}

	SetAlert(text: string) {
		this.$data.alertText = text;
		this.$data.alert = true;
	}
}
