




























































































































































import Vue from 'vue';
import Backbone from '@/core/Backbone';
import Component from 'vue-class-component';
import moment, { Moment } from 'moment';
import EditServiceVisitDlg from '@/dialogs/EditServiceVisit.vue';
import Address, { EAddressState } from '@/models/Address';
import UITools from '@/Misc/UITools';
import Well, { DTBarrier } from '@/models/Well';
import ScheduledVisit from '@/models/ScheduledVisit';
import EditWellDialog from '@/dialogs/EditWellDialog.vue';
import EditAddressDialog from '@/dialogs/EditCustomerDialog.vue';
import LabelValue from '@/dialogs/LabelValue.vue';
import { EServiceStatus } from '@/models/Address';
import Report from '@/models/Reports';
import BarrierType from '@/models/BarrierType';

@Component({
	components: {
		EditServiceVisitDlg: EditServiceVisitDlg,
		EditAddressDialog: EditAddressDialog,
		EditWellDialog: EditWellDialog,
		LabelValue: LabelValue
	},
	filters: {
		moment: function (date: string) {
			if (date == undefined) {
				return '-';
			}
			return moment(date).format('L');
		},
		momentWithTime: function (date: string) {
			if (date == undefined) {
				return '-';
			}
			return moment(date).format('L LT');
		},
		linebreak: function (value: string) {
			if (!value) {
				return '';
			}
			return value.replaceAll('\n', '<br />');
		}
	}
})
export default class AddressDetails extends Vue {
	data(): any {
		return {
			Moment: moment,
			address: <Address | undefined>undefined,
			EServiceStatus: EServiceStatus,
			UITools: UITools,
			Backbone: Backbone,
			tabAddress: null,
			selectedWells: <Well[] | undefined>undefined,
			selectedReports: <Report[] | undefined>undefined,
			selectedScheduledVisits: <ScheduledVisit[]>[],
			loading: true,
			uploadDialog: false
		};
	}

	private reports: ReportGroup[] = [];

	EditWell(well: Well) {
		let dlg = <EditWellDialog>this.$refs.editWellDialog;
		dlg.Open(well, this.$data.address, async () => {
			this.SetAddress(this.$data.address);
		});
	}

	CreateWell() {
		let i = new Well();
		i.CustomerId = this.$data.address.Id;
		i.OrganizationId = Backbone.State.User.OrganizationId;
		i.ServiceInterval = 12;
		const b = new DTBarrier();
		b.BarrierType = BarrierType.Types[0].Name;
		b.BarrierBrand = 'Nordisk Innovation';
		b.InstallationDirection = 'NA';
		i.Barriers = [b];

		this.EditWell(i);
	}

	private HandleAddressChanged(e: Address) {
		if (e == this.$data.address) {
			if (e.State == EAddressState.Active) {
				this.SetAddress(e);
			} else {
				this.SetAddress(undefined);
			}
		} else {
			e.OnChanged.unsubscribe(this.HandleAddressChanged);
		}
	}

	public async SetAddress(address: Address | undefined) {
		if (this.$data.address) {
			this.$data.address.OnChanged.unsubscribe(this.HandleAddressChanged);
		}

		this.$data.address = address;
		if (!address) {
			return;
		}

		this.$data.loading = true;

		await Backbone.RemotePoller.PollNow(true);

		address.OnChanged.subscribe(this.HandleAddressChanged);

		this.$data.selectedWells = address.Wells;

		let reportData = await Backbone.RemoteActions.Address.GetReports(address.Id);
		if (reportData.status < 300) {
			let reportGroups: ReportGroup[] = [];
			reportData.data.forEach((report) => {
				let group = reportGroups.find((group) => {
					return group.date.isSame(report.visitOrCreatedDate, 'day');
				});
				if (!group) {
					group = new ReportGroup();
					group.date = moment(report.visitOrCreatedDate);
					reportGroups.push(group);
				}

				group.reports.push(report);
			});
			reportGroups.sort((a, b) => b.date.valueOf() - a.date.valueOf());

			this.reports = reportGroups;
		} else {
			this.reports = [];
		}

		let scheduledVisits = await Backbone.RemoteActions.ScheduledVisit.ScheduledVisitsForCustomer(address.Id, undefined);
		if (scheduledVisits.status < 300) {
			scheduledVisits.data.sort((a, b) => {
				return a.TaskStartDate.diff(b.TaskStartDate);
			});
			this.$data.selectedScheduledVisits = scheduledVisits.data;
		} else {
			this.$data.selectedScheduledVisits = [];
		}

		this.$data.loading = false;
	}

	async EditAddress(address: Address) {
		let dlg = <EditAddressDialog>this.$refs.editAddressDialog;
		dlg.SetCustomer(address, async (address) => {
			if (!address) {
				// means deleted
				this.SetAddress(undefined);
				return;
			}
		});
	}

	EditService(serviceData: ScheduledVisit) {
		let dlg = <EditServiceVisitDlg>this.$refs.serviceDialog;
		//let address: Address = this.$data.address;

		dlg.Open(serviceData);
	}

	PlanService() {
		let dlg = <EditServiceVisitDlg>this.$refs.serviceDialog;
		let address: Address = this.$data.address;
		let serviceData = new ScheduledVisit({
			CustomerId: address.Id,
			TaskStartDate: moment(address.NextEstimatedServiceDate),
			TaskEndDate: moment(address.NextEstimatedServiceDate).add(1, 'hour'),
			UserId: address.IsPlanned ? address.NextScheduledServiceVisit.UserId : undefined
		});

		let scheduledVisits = this.$data.selectedScheduledVisits;
		if (scheduledVisits.length > 0) {
			let sv: ScheduledVisit = scheduledVisits[scheduledVisits.length - 1];
			serviceData.TaskStartDate = moment(sv.TaskStartDate).add(12, 'months');
			serviceData.TaskEndDate = moment(sv.TaskEndDate).add(12, 'months');
			serviceData.UserId = sv.UserId;
		}

		dlg.Open(serviceData);
	}

	Download(key: string) {
		window.open(Backbone.RemoteActions.GetFilePath(key));
	}

	DownloadReport(key: string) {
		window.open(Backbone.RemoteActions.apiUrl + 'mapi/report/' + key);
	}
}

class ReportGroup {
	public date: Moment = moment();
	public reports: Report[] = [];
}
