
















import Vue from 'vue';
import Component from 'vue-class-component';
import Backbone from '../core/Backbone';
import DTBannerInfo, { EBannerState } from '../models/DTBannerInfo';

@Component({})
export default class BannerCarousel extends Vue {
	Backbone = Backbone;
	banners: any = [];

	mounted() {
		this.getBanners();
	}

	async getBanners() {
		let response = await Backbone.RemoteActions.Banner.GetAll();
		if (response.status < 300) {
			this.banners = response.data.filter((x: DTBannerInfo) => {
				if (x.State === EBannerState.Active && x.DownloadKey) {
					return x;
				}
			});
			this.shuffleBanners();
		}
	}

	shuffleBanners() {
		var currentIndex = this.banners.length,
			randomIndex;

		while (currentIndex != 0) {
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			[this.banners[currentIndex], this.banners[randomIndex]] = [this.banners[randomIndex], this.banners[currentIndex]];
		}
	}

	openLink(banner: DTBannerInfo) {
		var url = banner.Url!;
		if (!url.startsWith('http')) {
			url = 'https://' + url;
		}
		window.open(url, '_blank');
	}
}
