

















import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';

@Component({})
export default class ConfirmDialog extends Vue {
	data(): any {
		return {};
	}

	private visible = false;
	private message = '';
	private zindex = 999;

	public Show(message: string = '') {
		this.message = message;
		this.visible = true;
		this.zindex = Backbone.UI.GetHighestZIndex() + 10;
		console.warn(message);
	}

	public Hide() {
		this.message = '';
		this.visible = false;
	}

	created() {
		Backbone.UI.Progress = this;
	}
}
