


















































import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';
import User, { RoleTypes } from '@/models/User';
import moment from 'moment';
import _ from 'lodash';

@Component({
	filters: {
		moment: function (date: string) {
			if (date == undefined) {
				return 'Aldrig';
			}
			return moment(date).format('L LT');
		}
	}
})
export default class UserEdit extends Vue {
	data(): any {
		return {
			valid: true,
			user: <User | undefined>undefined,
			loading: false,
			alertText: '',
			alert: false,
			emailRules: [
				(v: any) => !!v || 'E-mail skal udfyldes.',
				(v: any) => /.+@.+\..+/.test(v) || 'E-mailadressen er ikke formateret korrekt!'
			],
			nameRules: [(v: any) => !!v || 'Navn skal udfyldes.']
		};
	}

	private roleTypes = [
		{ text: 'Montør', value: RoleTypes.Technician },
		{ text: 'Administrator', value: RoleTypes.Administrator }
	];
	private originalUser: User | undefined;
	private Backbone = Backbone;
	private RoleTypes = RoleTypes;

	async SendInvite() {
		if (!(this.$refs.form as Vue & { validate: () => boolean }).validate()) {
			return;
		}
		this.$data.loading = true;
		let u: User = this.$data.user;
		let result = await Backbone.RemoteActions.Onboarding.SendInvite(u.Email, u.Name!, u.Role.toString());
		this.$data.loading = false;

		if (result.status > 299) {
			this.SetAlert('Fejl ved udsending af invitation.\nKode: ' + result.statusText);
			return;
		}

		Backbone.UI.PushAlert('Invitation sendt til ' + this.$data.user.Name, 'success', true);

		this.$data.user = undefined;

		this.RefreshData();
	}

	async RefreshData() {
		this.$data.loading = true;
		await Backbone.State.InitState();
		this.$data.loading = false;
	}

	async mounted() {
		await this.RefreshData();
	}

	async SaveChanges() {
		if (this.$data.user.Role == RoleTypes.SystemAdministrator) {
			alert('Brugeren er SystemAdministrator og kan derfor ikke ændres!');
			return;
		}
		this.$data.loading = true;
		let response = await Backbone.RemoteActions.User.Update(this.$data.user);
		this.$data.loading = false;

		if (response.status < 300) {
			Backbone.UI.PushAlert('Ændringerne er gemt for ' + this.$data.user.Name, 'success', true);
			//await this.RefreshData();
			if (this.originalUser) {
				Object.assign(this.originalUser, this.$data.user);
			}

			this.$data.user = undefined;
			return;
		}

		this.SetAlert('Der skete en fejl ved gemning af data!\nKode: ' + response.statusText);
	}

	SetAlert(text: string) {
		this.$data.alertText = text;
		this.$data.alert = true;
	}

	public Open(user: User): void {
		this.$data.user = _.cloneDeep<User>(user);
		this.originalUser = user;
	}
}
