















































































































































































































import Vue from 'vue';
import Backbone from '@/core/Backbone';
import Component from 'vue-class-component';
import moment from 'moment';
import UITools from '@/Misc/UITools';
import Address from '@/models/Address';
import {} from 'googlemaps';
import EditAddressDialog from '@/dialogs/EditCustomerDialog.vue';
import AddressDetails from './AddressDetails.vue';
import { EAddressState, EServiceStatus } from '@/models/Address';
import User from '@/models/User';
import DTCompany from '@/models/DTCompany';
import { Watch } from 'vue-property-decorator';
import _ from 'lodash';

@Component({
	filters: {
		moment: function (date: moment.Moment) {
			if (date == undefined) {
				return '-';
			}
			return date.format('L');
		}
	},
	components: {
		EditAddressDialog: EditAddressDialog,
		AddressDetails: AddressDetails
	},
	watch: {}
})
export default class AddressList extends Vue {
	UITools = UITools;
	Backbone = Backbone;
	gmap = {
		zoom: 7,
		center: {
			lat: 55.6606437,
			lng: 12.1088032
		}
	};
	tab = null;
	headers = [
		{
			text: 'Kundenavn',
			align: 'left',
			sortable: true,
			value: 'DisplayName'
		},
		{
			text: 'Adresse',
			align: 'left',
			sortable: true,
			value: 'Address'
		},
		{
			text: 'Planlagt',
			sortable: true,
			value: 'NextScheduledServiceVisit.DateStart',
			align: 'end'
		},
		{
			text: 'Estimeret service',
			sortable: true,
			value: 'NextEstimatedServiceDate',
			align: 'end'
		},
		{
			text: 'Status',
			sortable: true,
			value: 'ServiceStatus',
			width: 90,
			align: 'center'
		}
	];

	selectedCompany: DTCompany | null = null;

	private gMapRef: any = null;
	private addresses: Address[] = [];
	private statusFilter = {
		showOnlyCreated: false,
		user: <User | null>null,
		soon: true,
		overdue: true,
		done: true,
		planned: true,
		notPlanned: true,
		noAgr: true
	};
	private customerSearchString = '';
	private selectedAddress: Address | null = null;
	private loading = true;
	private mapHasBeenFit = false;
	sortBy = ['NextEstimatedServiceDate'];
	sortDesc = [false];

	addressSort(items: Address[]) {
		if (!this.sortBy || this.sortBy.length == 0 || items.length == 0) {
			return items;
		}

		const sortBy = this.sortBy[0];
		let _sortDesc = false;
		if (this.sortDesc.length > 0) {
			_sortDesc = this.sortDesc[0];
		}
		if (sortBy == 'NextEstimatedServiceDate') {
			return _sortDesc
				? items.sort((a, b) => a.NextEstimatedServiceDate.valueOf() - b.NextEstimatedServiceDate.valueOf())
				: items.sort((b, a) => a.NextEstimatedServiceDate.valueOf() - b.NextEstimatedServiceDate.valueOf());
		}

		if (sortBy == 'ServiceStatus') {
			return _sortDesc ? items.sort((a, b) => a.ServiceStatus - b.ServiceStatus) : items.sort((b, a) => a.ServiceStatus - b.ServiceStatus);
		}

		if (sortBy == 'NextScheduledServiceVisit.DateStart') {
			return _sortDesc
				? items.sort((a, b) => a.NextScheduledServiceVisit.DateStart.valueOf() - b.NextScheduledServiceVisit.DateStart.valueOf())
				: items.sort((b, a) => a.NextScheduledServiceVisit.DateStart.valueOf() - b.NextScheduledServiceVisit.DateStart.valueOf());
		}

		if (sortBy == 'Address') {
			return _sortDesc ? items.sort(this.compareAddress) : items.sort((a, b) => this.compareAddress(b, a));
		}

		if (sortBy == 'DisplayName') {
			return _sortDesc
				? items.sort((a, b) => a.DisplayName.localeCompare(b.DisplayName))
				: items.sort((b, a) => a.DisplayName.localeCompare(b.DisplayName));
		}

		return items;
	}

	compareAddress(a: Address, b: Address) {
		let res = (a.ZipAndCity || '').localeCompare(b.ZipAndCity || '', 'da-DK');
		if (res != 0) {
			return res;
		}

		return (a.Address || '').localeCompare(b.Address || '', 'da-DK');
	}

	ToggleFilter(sets: any) {
		if (sets.planned == false && this.statusFilter.notPlanned == false) {
			sets.notPlanned = true;
		} else if (sets.notPlanned == false && this.statusFilter.planned == false) {
			sets.planned = true;
		}

		Object.assign(this.statusFilter, sets);

		this.RefreshData();
	}

	@Watch('$route')
	private RefreshData() {
		this.loading = true;
		let adr: Address[] = [];

		if (this.statusFilter.showOnlyCreated) {
			const notBefore = moment().subtract(1, 'hours');
			Backbone.State.Addresses.forEach((address) => {
				if (address.State != EAddressState.Active) {
					return;
				}

				if (address.CreatedDate.isBefore(notBefore)) {
					return;
				}

				adr.push(address);
			});

			this.addresses = adr;
			this.FitMap();
			this.loading = false;

			return;
		}

		const cId = this.$route.query.selectedCompanyId;
		if (cId) {
			const c = Backbone.State.Companies.find((c) => {
				return c.Id == cId;
			});
			if (c && c != this.selectedCompany) {
				this.selectedCompany = c;
			}
		} else if (this.selectedCompany) {
			this.selectedCompany = null;
		}

		if ((this.statusFilter.soon || this.statusFilter.done || this.statusFilter.overdue) == false) {
			this.statusFilter.soon = true;
			this.statusFilter.done = true;
			this.statusFilter.overdue = true;
		}

		if (!this.statusFilter.planned) {
			this.statusFilter.user = null;
		}

		Backbone.State.Addresses.forEach((address) => {
			if (address.State != EAddressState.Active) {
				return;
			}

			if (!this.statusFilter.noAgr && !address.HasServiceAgreement) {
				return;
			}

			if (this.selectedCompany && address.CompanyId != this.selectedCompany.Id) {
				return;
			}

			if (!this.statusFilter.done) {
				switch (address.ServiceStatus) {
					case EServiceStatus.Done:
					case EServiceStatus.DoneNoAgreement:
					case EServiceStatus.DonePlanned:
						return;
				}
			}

			if (!this.statusFilter.soon) {
				switch (address.ServiceStatus) {
					case EServiceStatus.Soon:
					case EServiceStatus.SoonNoAgreement:
					case EServiceStatus.SoonPlanned:
						return;
				}
			}

			if (!this.statusFilter.overdue) {
				switch (address.ServiceStatus) {
					case EServiceStatus.Overdue:
					case EServiceStatus.OverdueNoAgreement:
					case EServiceStatus.OverduePlanned:
						return;
				}
			}

			if (!this.statusFilter.planned && address.IsPlanned) {
				return;
			} else if (!this.statusFilter.notPlanned && !address.IsPlanned) {
				return;
			}

			if (this.statusFilter.user) {
				if (!address.IsPlanned || address.NextScheduledServiceVisit.UserId != this.statusFilter.user.Id) {
					return;
				}
			}

			if (this.customerSearchString) {
				let str = this.customerSearchString.toLocaleUpperCase();
				if (address.DisplayName.toLocaleUpperCase().includes(str)) {
					adr.push(address);
				} else if (address.ZipCode && address.ZipCode.toLocaleUpperCase().includes(str)) {
					adr.push(address);
				} else if (address.City && address.City.toLocaleUpperCase().includes(str)) {
					adr.push(address);
				} else if (address.Address && address.Address.toLocaleUpperCase().includes(str)) {
					adr.push(address);
				} else if (address.CVR && address.CVR.toLocaleUpperCase().includes(str)) {
					adr.push(address);
				}
			} else {
				adr.push(address);
			}
		});

		this.addresses = adr;
		this.FitMap();
		this.loading = false;

		const customerId = this.$route.query.customerId;
		if (customerId) {
			const s = this.addresses.find((a) => {
				return a.Id == customerId;
			});
			if (s) {
				this.SelectAddress(s);
			}
		}
	}

	FitMap() {
		if (this.gMapRef == null || this.mapHasBeenFit) {
			return;
		}

		let bounds = UITools.GMapBoundsInfoFromPositions(this.addresses, 'Location');
		if (bounds != null) {
			this.$data.gmap.center = bounds.bounds.getCenter();
			this.gMapRef.zoom = bounds.zoomLevel;
			this.mapHasBeenFit = true;
		}
	}

	TapChangeHandler(pageIndex: number) {
		if (pageIndex != 1) {
			return;
		}

		this.FitMap();
	}

	async mounted() {
		(<any>this.$refs.gmap).$mapPromise.then((map: any) => {
			this.gMapRef = map;
		});

		this.RefreshData();
		Backbone.State.OnChanged.subscribe(() => this.RefreshData());
	}

	CreateAddressBtnClicked() {
		let dlg = <EditAddressDialog>this.$refs.editAddressDialog;
		dlg.SetCustomer(new Address(), async (customer) => {
			this.statusFilter.showOnlyCreated = true;
			this.RefreshData();
			if (!customer) {
				this.SelectAddress(null);
				return;
			}
			let sC: Address | undefined = this.addresses.find((e: Address) => e.Id == customer.Id);
			if (sC) {
				this.SelectAddress(sC);
			} else {
				this.SelectAddress(null);
			}
		});
	}

	async SelectAddress(customer: Address | null) {
		this.selectedAddress = customer;
		if (!customer) {
			return;
		}
		const q = _.cloneDeep(this.$route.query);
		q.customerId = customer!.Id;
		this.$router.push({ query: q });
		(<any>this.$refs.addressDetails).SetAddress(customer);
	}

	GetServiceState(item: Address, selectedVersion: boolean = false): string {
		return UITools.AddressServiceStateToPinMarker(item.ServiceStatus, selectedVersion);
	}

	GetServiceStateDescription(address: Address) {
		if (address.Wells.length == 0) {
			return 'Ingen brønde!';
		}

		let stateStr = '';
		if (address.IsPlanned) {
			stateStr = 'Planlagt (' + address.NextScheduledServiceVisit.UserName + ')';
		} else {
			stateStr = UITools.WellStatusToText(address.ServiceStatus);
		}

		return '<div class="font-weight-medium">' + stateStr + '</div>' + moment(address.NextEstimatedServiceDate).format('L') + '</div>';
	}

	companyChanged(c: DTCompany) {
		if (c) {
			this.$router.push({ path: 'addresslist', query: { selectedCompanyId: c.Id } });
		} else {
			this.$router.push({ path: 'addresslist', query: {} });
		}
	}

	resetFilters() {
		this.$router.push({ path: 'addresslist', query: {} });
		this.$router.go(0);
	}
}
