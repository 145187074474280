














































































































































































































/* eslint-disable prettier/prettier */

import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';
import User from '@/models/User';
import moment from 'moment';
import EditServiceVisitDlg from '@/dialogs/EditServiceVisit.vue';
import ScheduledVisit from '@/models/ScheduledVisit';
import UITools from '@/Misc/UITools';
import EditUserDialog from '@/dialogs/EditUserDialog.vue';
import {} from 'googlemaps';
import { EServiceStatus } from '../models/Address';

@Component({
	filters: {
		moment: function (date: string) {
			if (date == undefined) {
				return 'Aldrig';
			}
			return moment(date).format('L LT');
		}
	},
	components: {
		EditServiceVisitDlg: EditServiceVisitDlg,
		EditUserDialog: EditUserDialog
	}
})
export default class UsersAdmin extends Vue {
	calendarShowType = 'month';
	data(): any {
		return {
			calendarShowTypes: [
				{ text: 'Uge', value: 'week' },
				{ text: 'Måned', value: 'month' }
			],
			pickDate: false,
			calendarDate: new Date().toISOString().substr(0, 10),
			calendarDateMoment: <moment.Moment | undefined>undefined,
			center: {
				lat: 55.6606437,
				lng: 12.1088032
			},
			zoom: 10,
			tab: null,
			State: Backbone.State,
			headers: [
				{
					text: 'Medarbejder',
					align: 'left',
					sortable: true,
					value: 'Name'
				},
				{},
				{}
			],
			loading: true,
			alertText: '',
			alert: false,
			selectedUser: <User | undefined>undefined,
			selectedVisit: <ScheduledVisit | undefined>undefined,
			events: <CalendarEventWrapper[]>[]
		};
	}
	private gMapRef: any = null;
	private calendarStart: moment.Moment = moment();
	private calendarEnd: moment.Moment = moment();

	async RefreshData() {
		this.$data.loading = true;

		//Refresh user slist
		let response = await Backbone.RemoteActions.Onboarding.Users();

		if (response.status < 300) {
			Backbone.State.Users = response.data;
		}

		this.$data.loading = false;
		this.FitMap();
	}

	async mounted() {
		await this.RefreshData();
	}

	FitMap() {
		if (this.gMapRef == null) {
			return;
		}

		let bounds = UITools.GMapBoundsInfoFromPositions(this.$data.events, 'Location');
		if (bounds != null) {
			this.$data.center = bounds.bounds.getCenter();
			this.gMapRef.zoom = bounds.zoomLevel;
		}
	}

	TapChangeHandler(pageIndex: number) {
		if (pageIndex == 1) {
			let c: any = this.$refs.calendar;
			if (c) {
				window.setTimeout(() => {
					this.calendarShowType = 'week';
					window.setTimeout(() => {
						c.scrollToTime({ hour: 7, minute: 0 });
					}, 500);

				}, 500);
			}
		}

		if (pageIndex == 2) {
			this.FitMap();
		}
	}

	SetAlert(text: string) {
		this.$data.alertText = text;
		this.$data.alert = true;
	}

	async SelectUser(user: User) {
		this.$data.loading = true;
		this.$data.selectedUser = user;

		this.calendarStart = moment(this.$data.calendarDate);
		if (this.$data.calendarShowType == 'week') {
			this.calendarStart = this.calendarStart.weekday(0);
			this.calendarEnd = moment(this.calendarStart).add(7, 'days');
		} else {
			this.calendarStart = this.calendarStart.date(1); // first day of month
			this.calendarEnd = moment(this.calendarStart).add(1, 'month');

			this.calendarEnd = this.calendarEnd.weekday(7); // Set to first coming sunday
			this.calendarStart = this.calendarStart.weekday(0); // first monday before
		}

		let startDate = this.calendarStart;
		let endDate = this.calendarEnd;
		let response = await Backbone.RemoteActions.ScheduledVisit.ServiceVisitsForUser(user.Id || '', startDate.toDate(), endDate.toDate());

		let events: CalendarEventWrapper[] = [];
		if (response.status < 300) {
			response.data.forEach((event) => {
				events.push(new CalendarEventWrapper(event));
			});

			this.$data.events = events;
		}

		this.$data.loading = false;

		if (this.gMapRef == undefined) {
			(<any>this.$refs.gmap).$mapPromise.then((map: any) => {
				this.gMapRef = map;
				this.FitMap();
			});
		} else {
			this.$nextTick(() => {
				this.FitMap();
			});
		}
		let c: any = this.$refs.calendar;
		if (c) {
			c.scrollToTime({ hour: 7, minute: 0 });
		}
	}

	EditUser(user: User) {
		let dlg = this.$refs['editUserDialog'] as EditUserDialog;
		dlg.Open(user);
	}

	async DeleteUser(user: User) {
		let result = await Backbone.UI.OpenConfirmDialog(
			'Slet medarbejder. Er du sikker?',
			'Du er ved at slette medarbejderen \'' +
				user.Name +
				'\'.\nHandlingen kan ikke fortrydes og medarbejderen vil ikke have adgang til systemet mere.<br />Er du sikker?',
			true,
			{ color: 'error' }
		);

		if (result != true) {
			return;
		}

		this.$data.loading = true;

		let response = await Backbone.RemoteActions.User.Delete(user.Id || '');

		this.$data.loading = false;

		if (response.status < 300) {
			this.$data.selectedUser = undefined;
			this.RefreshData();
			Backbone.UI.PushAlert('Medarbejderen er slettet!', 'success', true);

			return;
		}

		this.SetAlert('Der skete en fejl ved sletning af medarbejderen!\nKode: ' + response.statusText);
	}

	CreateUser() {
		this.EditUser(new User());
	}

	async SelectVisit(visit: CalendarEventWrapper) {
		this.$data.selectedVisit = visit.ServiceVisit;
		let serviceDialog = <EditServiceVisitDlg>this.$refs.serviceDialog;
		serviceDialog.Open(visit.ServiceVisit, (s: ScheduledVisit | undefined) => {
			if (s) {
				this.SelectUser(this.$data.selectedUser);
			}
		});
	}

	GetServiceState(item: CalendarEventWrapper, selectedVersion: boolean = false): string {
		return UITools.AddressServiceStateToPinMarker(EServiceStatus.SoonPlanned, selectedVersion);
	}

	CalendarChangedHandler() {
		let date = moment(this.$data.calendarDate);
		let oldDate = this.$data.calendarDateMoment;
		if (this.$data.calendarShowType == 'week') {
			if (date.isSame(oldDate, 'week')) {
				return;
			}
		}
		if (this.$data.calendarShowType == 'month') {
			if (date.isSame(oldDate, 'month')) {
				return;
			}
		}

		this.$data.calendarDateMoment = date;

		this.SelectUser(this.$data.selectedUser);
	}

	TimeOfDay(date: any) {
		return date.time;
	}

	DayOfWeek(date: any) {
		switch (date.weekday) {
			case 0:
				return 'Søndag';
			case 1:
				return 'Mandag';
			case 2:
				return 'Tirsdag';
			case 3:
				return 'Onsdag';
			case 4:
				return 'Torsdag';
			case 5:
				return 'Fredag';
			case 6:
				return 'Lørdag';
		}
		return '??';
	}

	GetEventColor() {
		return 'primary'; // m.ServiceVisit == this.$data.selectedVisit ? 'primary' : 'accent';
	}

	CalendarTimeClicked(timeStamp: any) {
		let scheduledVisit: ScheduledVisit = new ScheduledVisit();

		if (timeStamp.time == '') {
			timeStamp.time = '09:00';
		}
		let fromTime = moment(timeStamp.date + ' ' + timeStamp.time);
		let minute = Math.floor(fromTime.minute() / 15) * 15;
		fromTime.minute(minute);
		let toTime = moment(fromTime);
		toTime.add(30, 'm');

		scheduledVisit.TaskStartDate = fromTime;
		scheduledVisit.TaskEndDate = toTime;
		scheduledVisit.UserId = this.$data.selectedUser.Id;
		this.$data.selectedVisit = scheduledVisit;
		let serviceDialog = <EditServiceVisitDlg>this.$refs.serviceDialog;
		serviceDialog.Open(scheduledVisit, (s: ScheduledVisit | undefined) => {
			if (s) {
				this.SelectUser(this.$data.selectedUser);
			}
		});
	}

	CalendarEventClicked(event: any) {
		event.nativeEvent.stopPropagation();
		let evt: CalendarEventWrapper = event.event;
		this.$data.selectedVisit = evt.ServiceVisit;
		let serviceDialog = <EditServiceVisitDlg>this.$refs.serviceDialog;
		serviceDialog.Open(evt.ServiceVisit, (s: ScheduledVisit | undefined) => {
			if (s) {
				this.SelectUser(this.$data.selectedUser);
			}
		});
	}

	private ICalUrl(user: User) {
		return window.location.origin + '/mapi/calendar/user/' + user.Id;
	}

	private CopyToClipboard() {
		let inp = (<any>this.$refs.iCal).$refs.input;
		inp.select();
		inp.setSelectionRange(0, 999);
		document.execCommand('copy');
		Backbone.UI.PushAlert('iCal url er kopieret til udklipholderen!', 'info', true);
	}
}

class CalendarEventWrapper {
	public ServiceVisit: ScheduledVisit;
	public color: string = '#ff0000';
	public get start(): string {
		return moment(this.ServiceVisit.TaskStartDate).format('YYYY-MM-DD H:mm');
	}

	public get end(): string {
		return moment(this.ServiceVisit.TaskEndDate).format('YYYY-MM-DD H:mm');
	}

	public get name(): string {
		return this.ServiceVisit.CustomerName || 'n/a';
	}

	public get Location(): any {
		return {
			lat: this.ServiceVisit.Location.Lat,
			lng: this.ServiceVisit.Location.Lng,
			Lat: this.ServiceVisit.Location.Lat,
			Lng: this.ServiceVisit.Location.Lng
		};
	}

	public showOnMap: boolean = true;

	public constructor(serviceVisit: ScheduledVisit) {
		this.ServiceVisit = serviceVisit;
	}
}
