

















































/* eslint-disable prettier/prettier */

import Vue from 'vue';
import Component from 'vue-class-component';
import Backbone from '../core/Backbone';
import zipcodes from '@/Misc/zipcodes';
import LabelValue from '@/dialogs/LabelValue.vue';
import _ from 'lodash';
import DTCompany from '@/models/DTCompany';
import TypeScriptUtils from '@/Misc/TypeScriptUtils';
import { AxiosResponse } from 'axios';

@Component({
	components: {
		LabelValue: LabelValue
	}
})
export default class EditCompanyDialog extends Vue {
	private company: DTCompany | null = null;
	private originalCompany: DTCompany | null = null;
	resultHandler: ((company: DTCompany | undefined) => void) | undefined = undefined;

	readonly emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	visible = false;
	loading = false;
	requiredField = [(v: string) => (TypeScriptUtils.isNullOrWhitespace(v) == true ? 'Dette felt skal udfyldes!' : true)];
	emailValidation = (v: string) => {
		const emails = v.split('\n');
		for (const mail of emails) {
			if (!this.emailRegEx.test(mail)) {
				return 'Feltet er ikke indtatset korrekt!';
			}
		}

		return true;
	}

	public SetCompany(company: DTCompany, resultHandler: ((c: DTCompany | undefined) => void) | undefined = undefined) {
		this.resultHandler = resultHandler;
		this.company = _.cloneDeep<DTCompany>(company);
		this.originalCompany = company;

		this.visible = true;
		this.loading = false;
	}

	async OkBtnClicked() {
		this.$data.valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();

		if (!this.$data.valid) {
			return;
		}

		this.loading = true;
		Backbone.UI.Progress.Show('Gemmer data...');

		let response: AxiosResponse<DTCompany> | null = null;
		if (TypeScriptUtils.isNullOrWhitespace(this.company?.Id)) {
			response = await Backbone.RemoteActions.Address.CreateCompany(this.company!);
		} else {
			response = await Backbone.RemoteActions.Address.UpdateCompany(this.company!);
		}
		if (response.status < 300) {
			await Backbone.RemotePoller.PollNow(true);
			this.loading = false;

			if (this.resultHandler != undefined) {
				this.resultHandler!(response.data);
			}
			this.visible = false;
			Backbone.UI.Progress.Hide();
			Backbone.UI.PushAlert('Ændringer til kundegruppen er gemt', 'success', true);

			if (TypeScriptUtils.isNullOrWhitespace(this.originalCompany!.Id)) {
				this.$router.push('companyList?id=' + response.data.Id);
				return;
			}

			return;
		}

		Backbone.UI.Progress.Hide();

		this.loading = false;
		Backbone.UI.PushApiErrorAlert('Kunne ikke gemme kundegruppen!', response, 'error', true);
	}

	CheckZip() {
		let city: any = (<any>zipcodes)[this.company!.ZipCode!];
		if (city != undefined) {
			this.company!.City = city;
		}
	}

	private async DeleteCustomer() {
		let result = await Backbone.UI.OpenConfirmDialog(
			'Slet kundegruppe. Er du sikker?',
			'Du er ved at slette kundegruppen \'' + this.company!.Name + '\'.\nHandlingen kan ikke fortrydes.<br />Er du sikker?',
			true,
			{ color: 'error' }
		);

		if (result != true) {
			return;
		}

		this.loading = true;

		let response = await Backbone.RemoteActions.Address.DeleteCompany(this.company!.Id);

		await Backbone.RemotePoller.PollNow(true);

		if (response.status < 300) {
			this.visible = false;
			Backbone.UI.PushAlert('Kundegruppen er slettet!', 'success', true);
			if (this.resultHandler != undefined) {
				this.resultHandler(undefined);
			}
		}

		this.loading = false;
	}
}
