




























import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';

const props = Vue.extend({
	props: {
		label: String,
		value: Object as () => moment.Moment,
		format: {
			type: String,
			default: 'HH:mm'
		},
		noValue: {
			type: String,
			default: 'Ikke angivet'
		},
		disabled: Boolean,
		css: {
			type: String,
			default: ''
		},
		prependIcon: {
			type: String,
			default: ''
		},
		prependInnerIcon: {
			type: String,
			default: ''
		},
		rules: {
			type: Array,
			default: (): any[] => []
		}
	}
});

@Component({
	watch: {
		value: {
			handler() {
				let a: any = this; //HACK this.RefreshData() does not transpile
				a.UpdateDisplayDate();
			}
		}
	}
})
export default class TimeField extends props {
	data(): any {
		return {
			visible: false,
			displayDate: <moment.Moment | undefined>undefined,
			pickerDate: ''
		};
	}

	created() {
		this.UpdateDisplayDate();
	}

	Prefix(value: string, prefix: string) {
		return (prefix + value).slice(-prefix.length + 1);
	}

	UpdateDisplayDate() {
		if (this.$props.value) {
			this.$data.pickerDate = this.$props.value.format('HH:mm');
			this.$data.displayDate = this.$props.value.format(this.$props.format);
		} else {
			this.$data.displayDate = this.$props.noValue;
			this.$data.pickerDate = moment().format('HH:mm');
		}
	}

	UpdateValue() {
		let da = this.$data.pickerDate.split(':');
		let d = moment(this.$props.value);
		d.hour(da[0]).minute(da[1]);

		this.$emit('input', d);
	}
}
