import {} from 'googlemaps';
import { EServiceStatus } from '@/models/Address';
import { EWellServiceStatus } from '@/models/Well';

export default class UITools {
	public static AddressServiceStateToPinMarker(state: EServiceStatus, selectedVersion: boolean = false): string {
		let result: string = '';
		switch (state) {
			case EServiceStatus.Done:
				result = '/gfx/pinDone';
				break;
			case EServiceStatus.DoneNoAgreement:
				result = '/gfx/pinDone_noAgr';
				break;
			case EServiceStatus.DonePlanned:
				result = '/gfx/pinDone_planned';
				break;

			case EServiceStatus.Soon:
				result = '/gfx/pinSoon';
				break;
			case EServiceStatus.SoonNoAgreement:
				result = '/gfx/pinSoon_noAgr';
				break;
			case EServiceStatus.SoonPlanned:
				result = '/gfx/pinSoon_planned';
				break;

			case EServiceStatus.Overdue:
				result = '/gfx/pinOverdue';
				break;
			case EServiceStatus.OverdueNoAgreement:
				result = '/gfx/pinOverdue_noAgr';
				break;
			case EServiceStatus.OverduePlanned:
				result = '/gfx/pinOverdue_planned';
				break;
		}

		if (selectedVersion) {
			result += '_sel';
		}

		return result + '.png';
	}

	public static WellServiceStateToPinMarker(state: EWellServiceStatus, selectedVersion: boolean = false): string {
		let result: string = '';
		switch (state) {
			case EWellServiceStatus.Done:
				result = '/gfx/well_done';
				break;
			case EWellServiceStatus.DonePlanned:
				result = '/gfx/well_done_planned';
				break;
			case EWellServiceStatus.DoneNoAgreement:
				result = '/gfx/well_done_noAgr';
				break;

			case EWellServiceStatus.Soon:
				result = '/gfx/well_soon';
				break;
			case EWellServiceStatus.SoonPlanned:
				result = '/gfx/well_soon_planned';
				break;
			case EWellServiceStatus.SoonNoAgreement:
				result = '/gfx/well_soon_noAgr';
				break;

			case EWellServiceStatus.Overdue:
				result = '/gfx/well_overdue';
				break;
			case EWellServiceStatus.OverduePlanned:
				result = '/gfx/well_overdue_planned';
				break;
			case EWellServiceStatus.OverdueNoAgreement:
				result = '/gfx/well_overdue_noAgr';
				break;
		}

		if (selectedVersion) {
			result += '_sel';
		}

		return result + '.png';
	}

	public static WellStatusToText(status: EServiceStatus) {
		let result: string = '';
		switch (status) {
			case EServiceStatus.Done:
			case EServiceStatus.DoneNoAgreement:
			case EServiceStatus.DonePlanned:
				result = 'Udført';
				break;

			case EServiceStatus.Soon:
			case EServiceStatus.SoonNoAgreement:
			case EServiceStatus.SoonPlanned:
				result = 'Kommende';
				break;

			case EServiceStatus.Overdue:
			case EServiceStatus.OverdueNoAgreement:
			case EServiceStatus.OverduePlanned:
				result = 'Overskredet';
				break;
		}

		return result;
	}

	public static GMapBoundsInfoFromPositions(
		array: any[],
		locationProperty: string
	): { bounds: google.maps.LatLngBounds; zoomLevel: number } | null {
		if (array.length == 0) {
			return null;
		}

		const latLng: google.maps.LatLng = new google.maps.LatLng(array[0][locationProperty].Lat, array[0][locationProperty].Lng);
		const result = { bounds: new google.maps.LatLngBounds(latLng), zoomLevel: 0 };

		array.forEach((c: any) => {
			result.bounds.extend(new google.maps.LatLng(c[locationProperty].Lat, c[locationProperty].Lng));
		});
		const size = result.bounds.toSpan();
		let biggest = size.lat();
		if (size.lng() > biggest) {
			biggest = size.lng();
		}
		if (biggest < 0.003) {
			result.zoomLevel = 16;
		} else if (biggest < 0.015) {
			result.zoomLevel = 15;
		} else if (biggest < 0.02) {
			result.zoomLevel = 14;
		} else if (biggest < 0.04) {
			result.zoomLevel = 13;
		} else if (biggest < 0.15) {
			result.zoomLevel = 11;
		} else if (biggest < 0.6) {
			result.zoomLevel = 10;
		} else if (biggest < 2) {
			result.zoomLevel = 9;
		} else if (biggest < 3.5) {
			result.zoomLevel = 8;
		} else {
			result.zoomLevel = 7;
		}

		return result;
	}
}
