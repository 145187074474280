



















































































/* eslint-disable prettier/prettier */

import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';
import User from '../models/User';
import Organisation from '../models/Organisation';
import ConfirmDialog from '../dialogs/ConfirmDialog.vue';

@Component({
	filters: {},
	components: {
		ConfirmDialog: ConfirmDialog
	}
})
export default class UsersAdmin extends Vue {
	data(): any {
		return {
			loading: true,
			user: <User>{},
			organisation: <Organisation>{},
			password: '',
			passwordRepeat: '',
			rules: {
				required: (value: string) => !!value || 'Skal udfyldes.',
				min: (value: string) => value.length >= 6 || 'Mindst 6 karakterer',
				match: () => {
					if (this.$data.password != this.$data.passwordRepeat) {
						return 'Matcher ikke passwordet!';
					}

					return true;
				}
			},
			valid: true
		};
	}

	async mounted() {
		let response = await Backbone.RemoteActions.Onboarding.InviteData(<string>this.$route.query.token);
		this.$data.loading = false;
		if (response.status < 300) {
			this.$data.user = response.data.User;
			this.$data.organisation = response.data.Organization;
			return;
		}
	}

	async ConfirmBtnClicked() {
		this.$data.loading = true;

		let response = await Backbone.RemoteActions.Onboarding.ConfirmInvite(<string>this.$route.query.token, this.$data.password);
		this.$data.loading = true;

		if (response.status < 300) {
			await Backbone.UI.OpenConfirmDialog(
				'Tilmeldt organisation!',
				'Du er nu tilmedt organisationen.\nDu kan nu benytte app\'en til registrering af brønde og servicebesøg.',
				false
			);

			this.$router.push('/');

			return;
		}
	}
}
