import { render, staticRenderFns } from "./BannerCarousel.vue?vue&type=template&id=8b47e516&scoped=true&"
import script from "./BannerCarousel.vue?vue&type=script&lang=ts&"
export * from "./BannerCarousel.vue?vue&type=script&lang=ts&"
import style0 from "./BannerCarousel.vue?vue&type=style&index=0&id=8b47e516&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b47e516",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCarousel,VCarouselItem,VImg,VRow})
