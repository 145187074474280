




























































import Vue from 'vue';
import Backbone from '../core/Backbone';
import Component from 'vue-class-component';

@Component({})
export default class ResetPassword extends Vue {
	data(): any {
		return {
			valid: false,
			email: '',
			password: '',
			passwordRepeat: '',
			rules: {
				psw: (v: string) => {
					if (!v) {
						return 'Password skal udfyldes.';
					}

					if (v.length < 6) {
						return 'Password skal være på mindst 6 karakterer.';
					}

					return true;
				},
				pswRepeat: (v: string) => v == this.$data.password || 'Passwords er ikke ens.'
			},
			loading: false,
			alert: false,
			dialog: false
		};
	}

	async mounted() {
		this.$data.email = <string>this.$route.query.email;
	}

	async confirm() {
		this.$data.loading = true;

		let response = await Backbone.RemoteActions.Onboarding.ResetPassword(<string>this.$route.query.token, this.$data.password);
		this.$data.loading = false;

		if (response && response.status < 300) {
			this.$data.dialog = true;
			return;
		}

		this.$data.alert = true;
	}
}
