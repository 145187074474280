













import Vue from 'vue';
import moment from 'moment';
const VersionInfo = require('@/VersionInfo.js').Version;

export default Vue.extend({
	name: 'App',
	components: {},
	data: () => ({
		VersionInfo: VersionInfo,
		BuildInfo: () => {
			return 'Build date:' + moment(VersionInfo.buildDate).format('lll');
		}
		//
	})
});

//   export default {
//     props: {
//       source: String,
//     },
//     data: () => ({
//       drawer: null,
//     }),
//   }
